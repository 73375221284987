import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import ServiceItem from '../components/service-item';
import ValueItem from '../components/value-item';
import TrendingIcon from '../icons/trending-up.svg';
import SafeguardIcon from '../icons/safeguard-check.svg';

// TODO: Agregar i18n en landing.
// TODO: Agregar video.

const IndexPage = ({ location }) => (
  <Layout title="Inicio" pathname={location.pathname}>
    <div className="py-12 flex flex-wrap justify-around items-center md:px-12">
      <div className="w-96 py-12 mx-12">
        <p className="text-gray font-semibold text-xl">
          Productora Agrícola del Caribe SAS{' '}
        </p>
        <p className="text-gray py-4">
          Somos una empresa dedicada a la producción, comercialización y
          exportación de productos agrícolas colombianos, los cuales cumplen con
          los estándares de calidad internacional (GLOBAL GAP, BPA, etc).
        </p>
        <Link to="/products">
          <button className="w-64 bg-secondary-light font-semibold text-blue py-2 px-8 rounded-lg shadow-sm">
            Ver Productos
          </button>
        </Link>
      </div>
      <div className="w-96 md:w-128 h-96 rounded-xl bg-gray overflow-hidden">
        <iframe
          allowFullScreen
          className="h-96 w-full"
          src="https://www.youtube.com/embed/ImRnLLbkg10?autoplay=1?rel=0"
        ></iframe>
      </div>
    </div>
    <div className="w-full px-4 py-8 md:py-16 bg-primary-dark flex flex-col">
      <div className="w-full flex justify-center text-white font-medium text-2xl tracking-wide pt-4">
        Nuestros Servicios
      </div>
      <div className="pb-4 flex flex-wrap justify-center md:justify-around">
        <ServiceItem
          title="Implementación de sistemas de gestión"
          description="ISO 9.001,
            ISO 14.001, ISO 45.001, GLOBAL GAP, BPA, Rain Forest Alliance,
            Fair Trade, etc., que agregen valor a los productos cosechados y a
            las empresas que los cultivan."
        />
        <ServiceItem
          title="Desarrollo de proyectos agrícolas"
          description="para el
            establecimiento, sostenimiento y cosecha de frutas frescas y otros
            vegetales. (Administración de fincas y proyectos agrícolas)."
        />
        <ServiceItem
          title="Desarrollo de proyectos industriales"
          description="orientados a
            productos agrícolas como plantas de transformación, congelación,
            procesamiento industrial que agregen valor a los productos
            cosechados y llevarlos al consumidor final exitosamente."
        />
      </div>
    </div>
    <div className="flex flex-wrap text-gray justify-center py-12 md:py-20">
      <div className="text-2xl font-medium pt-4 px-8 pb-8 tracking-wide">
        Nuestros valores
      </div>
      <div className="flex flex-wrap justify-center">
        <ValueItem
          title="Salud, calidad y comercio justo"
          description="
            Protección de la salud de nuestros clientes ofreciendo siempre
            frutas frescas, cultivadas de manera orgánica y respetando los
            estándares de calidad de la GLOBAL GAP y precios justos."
          iconUrl={SafeguardIcon}
        />
        <ValueItem
          title="Mejoramiento continuo"
          description="
          En nuestra organización mejoramos continuamente, promoviendo el uso
          de sistemas de gestión agrícolas como BPA, GLOBAL GAP, Rain Forest,
          Fair Trade, ISO 9.001, etc."
          iconUrl={TrendingIcon}
        />
      </div>
    </div>
  </Layout>
);

export default IndexPage;
