import React from 'react';
import PropTypes from 'prop-types';

import Footer from './footer';
import Header from './header';
import SEO from './seo';

const Layout = ({ children, pathname, title }) => {
  return (
    <div className="w-screen min-h-screen flex flex-col">
      <SEO title={title} />
      <Header pathname={pathname} />
      {children}
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pathname: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Layout;
