import React from 'react';
import PropTypes from 'prop-types';

const ServiceItem = ({ title, description }) => {
  return (
    <div className="text-white w-80 p-2 mx-4 text-sm mt-8 text-center">
      <span className="font-semibold">{title}</span> {description}
    </div>
  );
};

ServiceItem.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ServiceItem;
