import React from 'react';
import PropTypes from 'prop-types';

const ValueItem = ({ title, description, iconUrl }) => {
  return (
    <div className="flex flex-col items-center md:items-start w-96 py-6 px-4">
      <img src={iconUrl} alt="" className="h-12 w-12" />
      <p className="font-semibold mt-4 mb-2">{title}</p>
      <p className="text-sm text-center md:text-left">{description}</p>
    </div>
  );
};

ValueItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired,
};

export default ValueItem;
