import React from 'react';

// TODO: Agregar i18n e íconos.

const Footer = () => {
  return (
    <div className="flex flex-col w-full py-12 bg-secondary-dark items-center">
      <div className="text-2xl text-white px-16 pb-6 font-semibold">
        Contáctanos
      </div>
      <div className="flex flex-wrap items-start text-white">
        <div className="flex flex-col mx-16 my-4">
          <div className="font-semibold text-lg">Germán E. Pardo Gómez</div>
          <div className="text-lg my-2">Gerente</div>
          <div className="mb-1">(+57) 320-546-8349</div>
          <div>gerencia@pragribesas.com</div>
        </div>
        <div className="flex flex-col mx-16 my-4">
          <div className="font-semibold text-lg">
            Juan Sebastían Tapia Villareal
          </div>
          <div className="text-lg my-2">Comercial</div>
          <div className="mb-1">-</div>
          <div>comercial@pragribesas.com</div>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
